.section {
	-webkit-font-smoothing:antialiased;
	font-size:1.05em;
	h3 {
		font-weight: 600;
		font-size: 1.8em;
		margin-top: 5px;
		-webkit-font-smoothing:antialiased;
	}	
	h4 {
		color: gray;
		font-size: 0.95em;
	}
	input {
		box-shadow: none !important;
	}
	.btn-primary {
		border-radius: 0;
		font-family:'Lato';
		color: white;
		background: rgb(29,51,73);
		box-shadow: none;
		border: none;
		padding-left: 30px;
		padding-right: 30px;
		font-size: 1em;
		padding-top: 10px;
		padding-bottom: 10px;
		.glyphicon {
			font-size: 0.8em;
			margin-left: -5px;
		}
	}
	.btn-primary:hover {
		background: rgb(49,71,93);
	}

	.login-label {
		margin-bottom: 10px;
		font-size: 1.05em;
	}

}